import { SupabaseClient } from '@supabase/supabase-js';

import { Languages } from '@/app/i18n/settings';
import { Database } from '@/types/database.types';
import { Theme_enum } from '@/types/exported.types';

export const getProfile = async (supabaseClient: SupabaseClient<Database>) => {
  const userId = (await supabaseClient.auth.getUser())?.data?.user?.id;
  const profile = await supabaseClient.from('profile').select('*').eq('id', userId).single();
  return profile;
};

export const getProfileWithRole = async (supabaseClient: SupabaseClient<Database>, userId: string) => {
  const profile = await supabaseClient.from('profile').select('*, role(*), customer(*)').eq('id', userId).single();
  return profile;
};

export const getRoles = async (supabaseClient: SupabaseClient<Database>) => {
  const role = await supabaseClient.from('role').select('*');
  return role;
};

export const getProfileWithId = async (supabaseClient: SupabaseClient<Database>, userId: string) => {
  const profile = await supabaseClient.from('profile').select('*').eq('id', userId).single();
  return profile;
};

export const updateProfile = async (
  supabaseClient: SupabaseClient<Database>,
  userId: string,
  updates: {
    theme_preference?: Theme_enum;
    language?: Languages;
    name?: string | undefined;
    email_address?: string | undefined;
    phone_number?: number | undefined;
    phone_number_country_code?: string | undefined;
    customer_id?: string | undefined;
    newRoleId?: string | undefined;
  }
) => {
  const { newRoleId, ...rest } = updates;
  const { data: profile, error: profileError } = await supabaseClient.from('profile').update(rest).eq('id', userId);
  const { data: role, error: roleError } = await supabaseClient
    .from('profile_role_junction')
    .update({ role_id: newRoleId })
    .eq('profile_id', userId);
  const error = profileError?.message || roleError?.message || null;
  return { profile, role, error: error };
};

export const getAllUsers = async (supabaseClient: SupabaseClient<Database>) => {
  const users = await supabaseClient.from('profile').select('*');
  return users;
};

export const getAllOrganizations = async (supabaseClient: SupabaseClient<Database>) => {
  const organizations = await supabaseClient.from('organization').select('*');
  return organizations;
};
