'use client';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { getClientSupabaseClient } from '@/service/supabase.client';
import { getProfileWithRole } from '@/service/users.service';
import useAuthStore from '@/stores/auth.store';
import useLoadingPageStore from '@/stores/loadingPage.store';
import { useMediaQuery } from '@/utils';

import { BreadcrumbsToolbar } from './BreadcrumbsToolbar';
import { ConfirmSignUp } from './ConfirmSignup';
import { DrawerMenu } from './DrawerMenu';
import { LoadingComponent } from './LoadingComponent';
import { LoginPageWrapper } from './LoginPageWrapper';
import SettingsDialog from './SettingsDialog';

type Props = {
  themeCookie:
    | {
        name: string;
        value: string;
      }
    | undefined;
};

export const AuthWrapper: React.FC<Props & PropsWithChildren> = ({ children }) => {
  const supabase = getClientSupabaseClient();
  const user = useAuthStore((state) => state.user);
  const profile = useAuthStore((state) => state.profile);
  const isBreakpoint600 = useMediaQuery(600);
  const [loadingAuth, setLoadingAuth] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const loading = useLoadingPageStore((state) => state.loading);
  const setLoading = useLoadingPageStore((state) => state.setLoading);
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const pathName = usePathname() ?? '';
  const router = useRouter();
  const params = useParams();
  const lng = params?.lng ?? 'en';
  const setAuth = useAuthStore((state) => state.setAuth);
  const setProfile = useAuthStore((state) => state.setProfile);

  useEffect(() => {
    if (user) {
      (async () => {
        const { data: profileResponse, error } = await getProfileWithRole(supabase, user.id!);
        if (error) throw error;
        if (!profileResponse) throw new Error('No profile found');
        setProfile({ profile: profileResponse });
      })();
    }
  }, [user]);

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      // VERY IMPORTANT THAT WE DONT ADD REQUESTS TO SUPABASE IN HERE. It will lock up other requests, causing promises to never resolve. It was very painful to figure out.
      if (event === 'SIGNED_OUT') {
        router.push(`/${lng}/login`);
      } else {
        if (loadingAuth || event === 'USER_UPDATED') {
          if (event === 'USER_UPDATED') {
            setLoadingAuth(true);
          }
          const newUser = session?.user;
          if (newUser) {
            setAuth({ user: newUser });
          }
          if (loadingAuth) {
            setLoadingAuth(false);
          }
        }
      }
    });
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [lng, loadingAuth, router, setAuth, supabase]);

  useEffect(() => {
    setLoading(false);
  }, [pathName, setLoading]);

  const onCreatePassword = pathName.includes('create-password');

  const logout = async () => {
    setLoading(true);
    const { error } = await supabase.auth.signOut();
    if (error) console.error(error);
  };

  const searchParams = useSearchParams();
  const token_hash = searchParams?.get('token_hash');
  const shouldRedirectToLoginPage =
    !user && !pathName.includes('login') && !pathName.includes('create-password') && !token_hash && !loadingAuth;
  if (shouldRedirectToLoginPage) {
    router.push(`/${lng}/login?redirectUrl=${pathName}?${searchParams?.toString()}`);
  }
  if (token_hash) {
    return <ConfirmSignUp />;
  } else if (shouldRedirectToLoginPage || loadingAuth) {
    return <LoadingComponent></LoadingComponent>;
  } else if (pathName.split('/')[2] === 'login') {
    // If on login screen return page without sidebar
    return <LoginPageWrapper>{children}</LoginPageWrapper>;
  } else {
    return (
      <Box display={'flex'}>
        <SettingsDialog profile={profile} open={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
        {profile ? (
          <DrawerMenu
            profile={profile}
            setIsSettingsOpen={setIsSettingsOpen}
            open={open}
            setOpen={setOpen}
            logout={logout}
          />
        ) : null}
        {loading ? (
          <LinearProgress
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              left: 0,
            }}
          />
        ) : null}
        <Box display={'flex'} flexDirection={'column'} width={1 / 1}>
          <Toolbar sx={{ display: onCreatePassword ? 'none' : null }}>
            <IconButton sx={{ mr: 1, display: isBreakpoint600 ? 'flex' : 'none' }} onClick={() => setOpen(true)}>
              <MenuIcon />
            </IconButton>
            <BreadcrumbsToolbar />
          </Toolbar>
          <Box sx={{ flex: 1 }}>{children}</Box>
        </Box>
      </Box>
    );
  }
};
