'use client';
import { Box, CircularProgress, Container } from '@mui/material';
import Image from 'next/image';
import React from 'react';

import ProprtyaiSpinner from '@/components/ProprtyaiSpinner.svg';
import ProprtyaiSpinnerSlow from '@/components/ProprtyaiSpinnerSlow.svg';
import { isSafari } from '@/utils';

export const LoadingComponent = ({ showSlow, dontCenter }: { showSlow?: boolean; dontCenter?: boolean }) => {
  // const isServerSide = typeof navigator === 'undefined' || typeof window === 'undefined';
  const showLoadingSpinner = isSafari();
  const spinner = showLoadingSpinner ? (
    <CircularProgress />
  ) : (
    <Image
      priority
      style={{ height: '100px', width: '100px' }}
      alt="proprty.ai logo spinner"
      src={!!showSlow ? ProprtyaiSpinnerSlow : ProprtyaiSpinner}
    />
  );
  return (
    <Container sx={dontCenter ? {} : { height: '100vh' }}>
      <Box display={'flex'} height={'100%'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
        {spinner}
      </Box>
    </Container>
  );
};
