'use client';
import { create } from 'zustand';

import { LoadingPageStore } from '@/types/store.types';

const useLoadingPageStore = create<LoadingPageStore>((set) => ({
  loading: false,
  setLoading: (loading: boolean) => set(() => ({ loading: loading })),
}));

export default useLoadingPageStore;
