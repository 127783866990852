'use client';
import Apartment from '@mui/icons-material/Apartment';
import Business from '@mui/icons-material/Business';
import Home from '@mui/icons-material/Home';
import People from '@mui/icons-material/People';
import QueryStats from '@mui/icons-material/QueryStats';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams, usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { getOrganizationName } from '@/service/properties.service';
import { getClientSupabaseClient } from '@/service/supabase.client';

import { Crumb } from './Crumb';

export const BreadcrumbsToolbar: React.FC = () => {
  const supabase = getClientSupabaseClient();
  const [loadingBreadcrumb, setLoadingBreadcrumb] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const pathName = usePathname() ?? '';
  const params = useParams();
  const lng = params?.lng ?? 'en';
  const id = params?.id;

  const { t } = useTranslation(typeof lng === 'string' ? lng : 'en');

  const routes = [
    { path: '/properties', name: t('properties'), icon: Apartment },
    { path: '/users', name: t('users'), icon: People },
    { path: '/organizations', name: t('organizations'), icon: Business },
    { path: '/data', name: t('data'), icon: QueryStats },
  ];

  useEffect(() => {
    const fetchName = async (id: string) => {
      if (!id) return;
      setLoadingBreadcrumb(true);
      const { data, error } = await getOrganizationName(supabase, id);
      if (error || !data.name) setName(id);
      else setName(data.name);
      setLoadingBreadcrumb(false);
    };
    fetchName(id as string);
  }, [id, supabase]);

  return (
    <Breadcrumbs sx={{ display: { xs: 'none', md: 'flex' } }} maxItems={2}>
      <Crumb Icon={Home} isLast={pathName === '/'} name={t('home')} path={'/'} />;
      {pathName
        .split('?')[0]
        .split('/')
        .filter((route) => route !== 'en' && route !== 'da' && route !== '')
        .map((routeName, index, array) => {
          const routeToNavigate = Array(index + 1)
            .fill('')
            .map((_, index) => {
              return array[index];
            })
            .join('/');
          const uuidRegexExp =
            /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi; // test if uuid
          const isLast = index === array.length - 1;
          const route = routes.find((route) => route.path === `/${routeName}`);
          const splittedNavigation = routeToNavigate.split('/');
          const isPropertyId = splittedNavigation.length === 2 && uuidRegexExp.test(splittedNavigation[1]);

          if (!route)
            return isPropertyId && loadingBreadcrumb ? (
              <CircularProgress key={routeName} size={14} />
            ) : (
              <Crumb
                key={routeName}
                isLast={isLast}
                name={isPropertyId ? name : routeName}
                path={`/${lng}/${routeToNavigate}`}
              />
            );
          return isPropertyId && loadingBreadcrumb ? (
            <CircularProgress key={route.path} size={14} />
          ) : (
            <Crumb
              key={route.path}
              Icon={route.icon}
              isLast={isLast}
              name={isPropertyId ? name : route.name}
              path={`/${lng}/${route.path}`}
            />
          );
        })}
    </Breadcrumbs>
  );
};
