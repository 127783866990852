import { SupabaseClient } from '@supabase/supabase-js';
import { DateTime } from 'luxon';

import { Languages } from '@/app/i18n/settings';
import { Database } from '@/types/database.types';

export const getSingleProperty = async (supabase: SupabaseClient<Database>, id: string, lng: Languages) => {
  const property = await supabase
    .from('property')
    .select(
      `*, organization(*), part_lvl_2_prediction_condition(*, part_lvl_2: building_element_level_2(*, name: name->${lng}, description: description->${lng}, checklist: checklist->${lng})), buildings: building(*, addresses: address(*), task(*))`
    )
    .eq('id', id)
    .eq('part_lvl_2_prediction_condition.is_stale', false)
    .gte('part_lvl_2_prediction_condition.created_at', DateTime.now().minus({ days: 7 }).toISO())
    .single();
  return property;
};

export const getOrganizationById = async (supabase: SupabaseClient<Database>, id: string) => {
  const organization = await supabase.from('organization').select('*, property(id), task(*, )').eq('id', id).single();
  return organization;
};

export const getPropertiesByIds = async (supabase: SupabaseClient<Database>, id: string, lng: Languages) => {
  const property = await supabase
    .from('property')
    .select(
      `*, organization(*), part_lvl_2_prediction_condition(*, part_lvl_2: building_element_level_2(*, name: name->${lng}, description: description->${lng}, checklist: checklist->${lng})), buildings: building(*, addresses: address(*), task(*))`
    )
    .or(`id.eq.${id},organization_id.eq.${id}`)
    .eq('part_lvl_2_prediction_condition.is_stale', false)
    .gte('part_lvl_2_prediction_condition.created_at', DateTime.now().minus({ days: 7 }).toISO());
  return property;
};

export const getTasksByOrganizationId = async (supabase: SupabaseClient<Database>, id: string, lng: Languages) => {
  const { data } = await supabase
    .from('task')
    .select(
      `*, constructive_activity(*), preventive_activity(*), remedial_activity(*), improvement_activity(*), buildings: building(*), part_lvl_2_task(*, part_lvl_2: building_element_level_2(*, name: name->${lng}, description: description->${lng}, checklist: checklist->${lng}, part_lvl_1: building_element_level_1(*, name: name->${lng})), part_lvl_2_condition(*, buildings: building(*), images: condition_img(*), score(*, short_text: short_text->${lng}, long_text: long_text->${lng})))`
    )
    .eq('organization_id', id);
  return data;
};

export const getPropertyBfeNumber = async (supabase: SupabaseClient<Database>, id: string) => {
  return await supabase.from('property').select('bfe_number').eq('id', id).single();
};

export const getOrganizationName = async (supabase: SupabaseClient<Database>, id: string) => {
  return await supabase.from('organization').select('name: title').eq('id', id).single();
};
