import { Avatar, Box, ButtonBase, CircularProgress, Menu, MenuItem, Skeleton, Tooltip } from '@mui/material';
import * as R from 'ramda';
import React, { useEffect } from 'react';

import { getClientSupabaseClient } from '@/service/supabase.client';
import { getProfileWithRole } from '@/service/users.service';
import useAuthStore from '@/stores/auth.store';
import { Customer } from '@/types/exported.types';
import { stringToColor } from '@/utils';

type Props = {
  customerTitle?: string;
  setOpenCustomerMenu: React.Dispatch<React.SetStateAction<boolean>>;
  openCustomerMenu: boolean;
  profileId: string;
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
};

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: '24px',
      width: '24px',
      fontSize: '12px',
    },
    children: `${name[0]}${name[1]}`,
  };
}

export const CustomerAvatar: React.FC<Props> = ({
  profileId,
  customerTitle,
  openCustomerMenu,
  setOpenCustomerMenu,
  anchorEl,
  setAnchorEl,
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingUpdate, setLoadingUpdate] = React.useState<boolean>(false);
  const [customerList, setCustomerList] = React.useState<Customer[]>([]);
  const supabaseClient = getClientSupabaseClient();
  const user = useAuthStore((state) => state.user);
  const setProfile = useAuthStore((state) => state.setProfile);

  useEffect(() => {
    (async () => {
      const { data: customerListResponse, error } = await supabaseClient.from('customer').select('*');
      if (error) {
        throw error;
      }
      setCustomerList(R.sortBy(R.compose(R.toLower, R.prop('title')))(customerListResponse));
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenCustomerMenu(true);
  };
  const handleClose = () => {
    setOpenCustomerMenu(false);
    setAnchorEl(null);
  };

  const updateCustomerId = async (customerId: string) => {
    setLoadingUpdate(true);
    const { error: updateProfileError } = await supabaseClient
      .from('profile')
      .update({ customer_id: customerId })
      .eq('id', profileId);
    if (updateProfileError) {
      setLoadingUpdate(false);
      throw updateProfileError;
    } else {
      if (user) {
        const { data: profileResponse, error } = await getProfileWithRole(supabaseClient, user.id!);
        if (error) {
          setLoadingUpdate(false);
          throw error;
        }
        if (!profileResponse) throw new Error('No profile found');
        setProfile({ profile: profileResponse });
        const currentLocation = window.origin;
        window.location.href = currentLocation; // We want to refresh the whole thing to make sure we're not stuck with old data
      }
    }
  };
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openCustomerMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {!loading ? (
          customerList.map((customer) => {
            return (
              <MenuItem
                key={customer.id}
                onClick={async () => {
                  handleClose();
                  await updateCustomerId(customer.id);
                }}
              >
                {customer.title}
              </MenuItem>
            );
          })
        ) : (
          <Box>
            {Array(8)
              .fill('')
              .map((data, index) => {
                return <Skeleton key={index} width={260} sx={{ margin: '8px 16px' }} />;
              })}
          </Box>
        )}
      </Menu>
      {customerTitle ? (
        loadingUpdate ? (
          <CircularProgress size={24} />
        ) : (
          <Tooltip title={customerTitle}>
            <ButtonBase
              id="basic-button"
              aria-controls={openCustomerMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openCustomerMenu ? 'true' : undefined}
              onClick={handleClick}
            >
              <Avatar {...stringAvatar(customerTitle)} />
            </ButtonBase>
          </Tooltip>
        )
      ) : null}
    </>
  );
};
