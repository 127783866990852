'use client';
import { Alert, Box, Button, Snackbar, Stack, Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import Image from 'next/image';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import React, { useState } from 'react';

import { useTranslation } from '@/app/i18n/client';
import SplashScreenAnimation from '@/components/SplashScreenAnimation.svg';
import SplashScreenLogo from '@/components/SplashScreenLogo.svg';
import { getClientSupabaseClient } from '@/service/supabase.client';
import { isSafari } from '@/utils';
import { supportEmailAddress } from '@/utils/config';

export const ConfirmSignUp: React.FC = () => {
  const router = useRouter();
  const params = useParams();
  const [error, setError] = useState<string | null>(null);
  const searchParams = useSearchParams();
  const token_hash = searchParams?.get('token_hash');
  const lng = params?.lng ?? 'en';
  const { t } = useTranslation(typeof lng === 'string' ? lng : 'da');
  const verifySignInAndRedirect = async () => {
    if (token_hash) {
      const { error } = await getClientSupabaseClient().auth.verifyOtp({ token_hash, type: 'email' });
      if (error) {
        setError(error.message);
      } else {
        router.push('/create-password');
      }
    }
  };
  return (
    <Box component={Stack} direction="column" justifyContent="center" alignItems="center" height={'100vh'}>
      <Snackbar open={!!error}>
        <Alert severity="error">
          <Typography>{error}</Typography>
          <Typography>Reach out to {supportEmailAddress} for support</Typography>
        </Alert>
      </Snackbar>
      <Image
        priority
        style={{ height: '300px', width: 'auto' }}
        alt="proprty.ai logo"
        src={isSafari() ? SplashScreenLogo : SplashScreenAnimation}
      />
      <Box mt={4}>
        <Zoom in style={{ transitionDelay: '4100ms' }}>
          <Button size="large" disabled={!token_hash || !!error} variant="contained" onClick={verifySignInAndRedirect}>
            {t('confirmSignUp')}
          </Button>
        </Zoom>
      </Box>
    </Box>
  );
};
