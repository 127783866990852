export default function SmallLogo() {
  return (
    <svg width="136" height="26" viewBox="0 0 136 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6641 0L17.4963 2.93911L11.6641 5.87821L5.83203 2.93911L11.6641 0Z" fill="#F4EDDB" />
      <path d="M17.4962 6.46582L23.3283 9.40493L17.4962 12.344L11.6641 9.40493L17.4962 6.46582Z" fill="#F4EDDB" />
      <path d="M5.83211 9.99316L11.6642 12.9323L5.83211 15.8714L0 12.9323L5.83211 9.99316Z" fill="#F4EDDB" />
      <path d="M11.6641 16.459L17.4963 19.3981L11.6641 22.3372L5.83203 19.3981L11.6641 16.459Z" fill="#F4EDDB" />
      <path
        d="M5.83203 2.93896L11.6641 5.87807V9.405L17.4963 12.3441V19.398L11.6641 16.4589V12.9319L5.83203 9.99282V2.93896Z"
        fill="#187770"
      />
      <path
        d="M17.4962 12.3444L23.3283 9.40527V19.9861L11.6641 25.8643V22.3373L17.4962 19.3982V12.3444Z"
        fill="#86B2A5"
      />
      <path d="M17.4962 2.93896L11.6641 5.87807V9.405L17.4962 6.46589V2.93896Z" fill="#86B2A5" />
      <path d="M11.6641 12.9321L5.83203 15.8712V19.3982L11.6641 16.4591V12.9321Z" fill="#86B2A5" />
      <path d="M0 12.9321L5.83211 15.8712V19.3982L11.6642 22.3373V25.8642L0 19.986V12.9321Z" fill="#187770" />
      <path
        d="M33.4717 22.7936V7.4732H36.0729V12.3274L35.7247 12.3069C35.7793 11.1872 36.0114 10.2451 36.4211 9.48042C36.8307 8.70211 37.3837 8.12179 38.0801 7.73947C38.7765 7.34348 39.5616 7.14549 40.4355 7.14549C41.2138 7.14549 41.917 7.28887 42.5451 7.57561C43.1869 7.86236 43.7331 8.26517 44.1837 8.78404C44.6479 9.28926 44.9961 9.89006 45.2282 10.5864C45.474 11.2692 45.5969 12.0202 45.5969 12.8394V13.3105C45.5969 14.1161 45.4809 14.874 45.2487 15.584C45.0166 16.2804 44.6752 16.888 44.2246 17.4069C43.7877 17.9258 43.2483 18.3354 42.6066 18.6358C41.9785 18.9225 41.2548 19.0659 40.4355 19.0659C39.5889 19.0659 38.8243 18.8952 38.1415 18.5539C37.4725 18.1988 36.9331 17.6663 36.5235 16.9563C36.1138 16.2326 35.8885 15.3177 35.8476 14.2117L36.7488 15.4406V22.7936H33.4717ZM39.5138 16.3213C40.0737 16.3213 40.5584 16.1848 40.968 15.9117C41.3913 15.6386 41.719 15.2563 41.9512 14.7647C42.1833 14.2732 42.2993 13.7065 42.2993 13.0647C42.2993 12.423 42.1833 11.8631 41.9512 11.3852C41.719 10.9073 41.3981 10.5386 40.9885 10.2792C40.5789 10.0061 40.0873 9.86958 39.5138 9.86958C39.0223 9.86958 38.558 9.98564 38.1211 10.2178C37.6841 10.4499 37.3291 10.7844 37.056 11.2214C36.7966 11.6447 36.6668 12.1635 36.6668 12.778V13.5358C36.6668 14.123 36.8034 14.6282 37.0765 15.0515C37.3632 15.4611 37.7251 15.7752 38.162 15.9936C38.599 16.2121 39.0496 16.3213 39.5138 16.3213Z"
        fill="#187770"
      />
      <path
        d="M47.873 18.6972V7.4732H50.4741V12.2864H50.4127C50.4127 10.7025 50.7472 9.48042 51.4163 8.62019C52.099 7.75995 53.0822 7.32983 54.3657 7.32983H54.7958V10.1563H53.9765C53.0753 10.1563 52.379 10.4021 51.8874 10.8937C51.3958 11.3716 51.15 12.068 51.15 12.9828V18.6972H47.873Z"
        fill="#187770"
      />
      <path
        d="M61.9024 19.0864C60.9192 19.0864 60.0453 18.9362 59.2807 18.6358C58.516 18.3217 57.8674 17.8984 57.3349 17.3659C56.816 16.8334 56.4201 16.2258 56.147 15.543C55.8739 14.8467 55.7373 14.1161 55.7373 13.3515V12.8804C55.7373 12.1021 55.8739 11.3647 56.147 10.6684C56.4337 9.95833 56.8434 9.33705 57.3759 8.80452C57.9221 8.27199 58.5775 7.85553 59.3421 7.55513C60.1068 7.24108 60.9602 7.08405 61.9024 7.08405C62.8718 7.08405 63.7321 7.24108 64.4831 7.55513C65.2477 7.85553 65.8963 8.27199 66.4288 8.80452C66.9614 9.33705 67.371 9.95833 67.6578 10.6684C67.9445 11.3647 68.0879 12.1021 68.0879 12.8804V13.3515C68.0879 14.1161 67.9513 14.8467 67.6782 15.543C67.4051 16.2258 67.0023 16.8334 66.4698 17.3659C65.9509 17.8984 65.3092 18.3217 64.5445 18.6358C63.7799 18.9362 62.8991 19.0864 61.9024 19.0864ZM61.9024 16.3213C62.5441 16.3213 63.0767 16.1848 63.4999 15.9117C63.9232 15.6386 64.2441 15.2563 64.4626 14.7647C64.6947 14.2732 64.8108 13.727 64.8108 13.1262C64.8108 12.4844 64.6947 11.9246 64.4626 11.4467C64.2305 10.9551 63.8959 10.566 63.459 10.2792C63.0357 9.99247 62.5168 9.84909 61.9024 9.84909C61.3016 9.84909 60.7827 9.99247 60.3457 10.2792C59.9088 10.566 59.5743 10.9551 59.3421 11.4467C59.1237 11.9246 59.0144 12.4844 59.0144 13.1262C59.0144 13.727 59.1237 14.2732 59.3421 14.7647C59.5606 15.2563 59.8883 15.6386 60.3253 15.9117C60.7622 16.1848 61.2879 16.3213 61.9024 16.3213Z"
        fill="#187770"
      />
      <path
        d="M70.375 22.7936V7.4732H72.9761V12.3274L72.628 12.3069C72.6826 11.1872 72.9147 10.2451 73.3243 9.48042C73.734 8.70211 74.287 8.12179 74.9834 7.73947C75.6797 7.34348 76.4649 7.14549 77.3388 7.14549C78.1171 7.14549 78.8203 7.28887 79.4484 7.57561C80.0902 7.86236 80.6363 8.26517 81.0869 8.78404C81.5512 9.28926 81.8994 9.89006 82.1315 10.5864C82.3773 11.2692 82.5002 12.0202 82.5002 12.8394V13.3105C82.5002 14.1161 82.3841 14.874 82.152 15.584C81.9199 16.2804 81.5785 16.888 81.1279 17.4069C80.691 17.9258 80.1516 18.3354 79.5098 18.6358C78.8817 18.9225 78.158 19.0659 77.3388 19.0659C76.4922 19.0659 75.7275 18.8952 75.0448 18.5539C74.3757 18.1988 73.8364 17.6663 73.4267 16.9563C73.0171 16.2326 72.7918 15.3177 72.7508 14.2117L73.652 15.4406V22.7936H70.375ZM76.4171 16.3213C76.9769 16.3213 77.4617 16.1848 77.8713 15.9117C78.2946 15.6386 78.6223 15.2563 78.8544 14.7647C79.0865 14.2732 79.2026 13.7065 79.2026 13.0647C79.2026 12.423 79.0865 11.8631 78.8544 11.3852C78.6223 10.9073 78.3014 10.5386 77.8918 10.2792C77.4821 10.0061 76.9906 9.86958 76.4171 9.86958C75.9255 9.86958 75.4613 9.98564 75.0243 10.2178C74.5874 10.4499 74.2324 10.7844 73.9593 11.2214C73.6998 11.6447 73.5701 12.1635 73.5701 12.778V13.5358C73.5701 14.123 73.7067 14.6282 73.9797 15.0515C74.2665 15.4611 74.6283 15.7752 75.0653 15.9936C75.5022 16.2121 75.9528 16.3213 76.4171 16.3213Z"
        fill="#187770"
      />
      <path
        d="M84.7762 18.6972V7.4732H87.3774V12.2864H87.316C87.316 10.7025 87.6505 9.48042 88.3196 8.62019C89.0023 7.75995 89.9854 7.32983 91.269 7.32983H91.6991V10.1563H90.8798C89.9786 10.1563 89.2822 10.4021 88.7907 10.8937C88.2991 11.3716 88.0533 12.068 88.0533 12.9828V18.6972H84.7762Z"
        fill="#187770"
      />
      <path
        d="M98.757 18.8406C97.6237 18.8406 96.7088 18.7041 96.0124 18.431C95.316 18.1442 94.804 17.6731 94.4763 17.0177C94.1622 16.3487 94.0052 15.4475 94.0052 14.3141V4.44189H97.057V14.437C97.057 14.9695 97.1935 15.3792 97.4666 15.6659C97.7534 15.939 98.1562 16.0756 98.6751 16.0756H100.334V18.8406H98.757ZM92.3052 9.86958V7.4732H100.334V9.86958H92.3052Z"
        fill="#187770"
      />
      <path
        d="M102.415 23.1418V20.3563H104.75C105.105 20.3563 105.405 20.3085 105.651 20.2129C105.897 20.131 106.095 19.9876 106.245 19.7828C106.395 19.578 106.518 19.298 106.614 18.943L109.379 7.4732H112.492L109.358 19.5984C109.14 20.4723 108.833 21.1687 108.437 21.6876C108.054 22.2065 107.535 22.5751 106.88 22.7936C106.238 23.0257 105.419 23.1418 104.422 23.1418H102.415ZM105.938 18.4515V15.8912H108.314V18.4515H105.938ZM104.586 18.4515L101.145 7.4732H104.443L107.699 18.4515H104.586Z"
        fill="#187770"
      />
      <path d="M112.967 18.6972V15.031H116.572V18.6972H112.967Z" fill="#187770" />
      <path
        d="M125.859 18.6972V15.3792H125.306V11.7744C125.306 11.2009 125.169 10.7708 124.896 10.484C124.623 10.1973 124.186 10.0539 123.585 10.0539C123.285 10.0539 122.889 10.0607 122.397 10.0744C121.906 10.088 121.401 10.1085 120.882 10.1358C120.363 10.1631 119.892 10.1905 119.468 10.2178V7.45272C119.782 7.42541 120.151 7.3981 120.574 7.37079C120.998 7.34349 121.435 7.323 121.885 7.30935C122.336 7.29569 122.759 7.28887 123.155 7.28887C124.302 7.28887 125.265 7.45272 126.043 7.78043C126.835 8.10814 127.436 8.60653 127.845 9.2756C128.255 9.93102 128.46 10.7776 128.46 11.8153V18.6972H125.859ZM122.274 18.984C121.469 18.984 120.759 18.8406 120.144 18.5539C119.544 18.2671 119.072 17.8575 118.731 17.325C118.39 16.7788 118.219 16.1302 118.219 15.3792C118.219 14.5599 118.431 13.8908 118.854 13.372C119.277 12.8394 119.871 12.4503 120.636 12.2045C121.401 11.9451 122.281 11.8153 123.278 11.8153H125.674V13.6382H123.258C122.684 13.6382 122.24 13.7816 121.926 14.0683C121.626 14.3414 121.476 14.7169 121.476 15.1948C121.476 15.6454 121.626 16.0141 121.926 16.3009C122.24 16.574 122.684 16.7105 123.258 16.7105C123.626 16.7105 123.954 16.6491 124.241 16.5262C124.541 16.3896 124.787 16.1643 124.978 15.8503C125.169 15.5362 125.278 15.0993 125.306 14.5394L126.084 15.3587C126.016 16.137 125.825 16.7924 125.511 17.325C125.21 17.8575 124.787 18.2671 124.241 18.5539C123.708 18.8406 123.053 18.984 122.274 18.984Z"
        fill="#187770"
      />
      <path
        d="M131.773 18.6972V7.4732H135.05V18.6972H131.773ZM130.278 9.89006V7.4732H135.05V9.89006H130.278Z"
        fill="#187770"
      />
      <g clipPath="url(#clip0_802_20873)">
        <path
          d="M131.768 6.04401C132.118 6.39456 132.582 6.5875 133.076 6.5875C133.569 6.5875 134.033 6.39456 134.384 6.04401C135.105 5.32275 135.105 4.14921 134.384 3.42776C134.033 3.0774 133.569 2.88428 133.076 2.88428C132.583 2.88428 132.118 3.0774 131.768 3.42795C131.046 4.14902 131.046 5.32275 131.768 6.04401V6.04401ZM133.133 3.25721C133.442 3.26833 133.739 3.37699 133.983 3.56761L133.076 4.47441L132.805 4.20433C133.02 3.93074 133.137 3.5924 133.133 3.25721V3.25721ZM132.541 3.9398L132.169 3.56761C132.343 3.43065 132.547 3.33514 132.764 3.28829C132.761 3.51693 132.682 3.74649 132.541 3.9398ZM132.278 4.19952C132.089 4.32826 131.866 4.39647 131.638 4.39505C131.686 4.18925 131.778 3.99628 131.907 3.82937L132.278 4.19952ZM131.598 4.76299C131.612 4.76336 131.626 4.76558 131.64 4.76558C131.966 4.76662 132.283 4.66097 132.543 4.46479L132.814 4.73598L131.907 5.64259C131.712 5.39061 131.603 5.08197 131.598 4.76299V4.76299ZM133.872 5.27077C134.065 5.13055 134.294 5.05101 134.521 5.04768C134.475 5.26438 134.38 5.46782 134.244 5.64278L133.872 5.27077ZM133.982 5.90435C133.816 6.03474 133.622 6.1275 133.416 6.17628C133.414 5.94722 133.483 5.72304 133.612 5.53401L133.982 5.90435V5.90435ZM133.049 6.21623C132.73 6.21115 132.42 6.10162 132.169 5.90435L133.076 4.99755L133.346 5.26818C133.142 5.5353 133.039 5.87235 133.049 6.21623ZM134.552 4.67901C134.218 4.67568 133.88 4.79185 133.607 5.00606L133.337 4.73598L134.244 3.82937C134.433 4.0733 134.541 4.37049 134.552 4.67901V4.67901Z"
          fill="#187770"
        />
      </g>
      <defs>
        <clipPath id="clip0_802_20873">
          <rect width="4.05712" height="4.05712" fill="white" transform="translate(131.064 2.72217)" />
        </clipPath>
      </defs>
    </svg>
  );
}
