'use client';
import { create } from 'zustand';

import { AuthStore } from '@/types/store.types';

const initialState = {
  user: null,
  profile: null,
};

const useAuthStore = create<AuthStore>()((set, get) => ({
  ...initialState,
  setAuth: ({ user }) => set((state) => ({ ...state, user })),
  setProfile: ({ profile }) => set((state) => ({ ...state, profile })),
  getAuth: () => get(),
  resetState: () => set(() => initialState),
}));

export default useAuthStore;
